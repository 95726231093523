import { CSSObject } from '@chakra-ui/react';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    display: 'flex',
    variant: 'secondary',
    h: '22px',
    px: '1.5',
    w: 'fit-content',
    borderRadius: 'base',
    spanProps: {
      fontFamily: 'accent',
      fontWeight: 'bold',
      fontSize: '11px',
      textTransform: 'uppercase',
      pt: '0.5',
    },
    _disabled: {
      boxShadow: 'none',
      border: 'none',
      bg: 'blackAlpha.300',
      color: 'beta.50',
      opacity: '1',
      '& > span': {
        _first: {
          color: 'green.300',
          mr: '1',
        },
      },
    },
    sx: {
      '.chakra-button__icon': {
        display: 'none',
      },
      ':active, &[data-active=true]': {
        bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'beta.300',
        _before: {
          display: 'none',
        },
      },
      '&&[data-isbonusbet="true"]': {
        _disabled: {
          w: '100%',
          py: '0.5',
          bgGradient: 'linear(to-b, blackAlpha.600, blackAlpha.600)',
          borderRadius: 'md',
          pointerEvents: 'none',
          '.chakra-button__icon': {
            display: 'block',
            color: 'green.400',
            mr: '0.5',
          },
          textShadow: 'none',
          lineHeight: 'normal',
          span: {
            fontWeight: 'medium',
            fontSize: '2xs',
            fontFamily: 'accent',
            color: 'white',
            pt: '0.5',
            textTransform: 'uppercase',
          },
        },
        bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
        color: 'beta.300',
        border: 'none',
        px: '1.5',
        h: '22px',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        textShadow: 'none',
        spanProps: {
          fontFamily: 'accent',
          fontWeight: 'normal',
          fontSize: '11px',
          pt: '0.5',
        },
        _hover: {
          border: 'none',
          boxShadow: 'none',
        },
        _before: {
          display: 'none',
        },
      },
    },
  },
  buttonBetPropositionClosedProps: {
    variant: 'unstyled',
    bg: 'red.500',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    borderRadius: 'base',
    color: 'blackAlpha.800',
    fontWeight: 'medium',
    fontFamily: 'accent',
    fontSize: 'xs',
    px: '3',
    py: '2',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  buttonStakeProps: {
    variant: 'solid',
    borderRadius: 'md',
  },
  spanRaceInfo: {
    color: 'white',
  },
  iconMysteryBetFooter: {
    color: 'beta.300',
    mt: '0.5',
    mr: '1',
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    lineHeight: 'normal',
    w: '100%',
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'gamma.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    px: 3,
    py: 1.5,
    fontSize: 'xs',
  },
  flexWrapperMysteryBetFooterBetReview: {
    fontSize: 'xs',
    w: '100%',
    borderRadius: 'md',
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'beta.300',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    _last: {
      bg: 'alpha.600',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.2)',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  spanBetOdds: {
    fontSize: '2xs',
    color: 'alpha.100',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapperMysteryRollover: {
    pb: '0',
  },
};

export const BetCardContainer = (): CSSObject => ({
  borderColor: 'whiteAlpha.300',
  border: '1px solid',
  bg: 'linear-gradient(116deg, rgba(255, 255, 255, 0.12) 1.57%, rgba(255, 255, 255, 0.05) 100%)',
  borderRadius: '8px',
  color: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(4px)',
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
};

export const ExoticsNumberContainer: CSSObject = {
  w: '6 ',
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'sm',
};

export const BetOdds: CSSObject = {
  display: 'none',
};

export const EventTitle: CSSObject = {
  fontFamily: 'accent',
  fontWeight: 'bold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'beta.300',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
});

export const EventSubtitle: CSSObject = {
  fontWeight: 'bold',
  color: 'alpha.200',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'alpha.700',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeCollapse: CSSObject = {
  '&&': {
    m: '0',
    mt: '2',
  },
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
};

export const BetCardSVG = (header: boolean) => ({
  color: 'beta.300',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});
