import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1',
    mb: '2',
    mt: '3',
  },

  container: { display: 'contents' },
  racingBtnsContainer: { display: 'contents' },
  sportBtnsContainer: { display: 'contents' },

  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'beta.300',
    boxSize: '20px',
    mr: 0,
    filter: 'drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.20))',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'beta.300',
    boxSize: '20px',
    filter: 'drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.20))',
    mr: 0,
  },

  linkIconBox: {
    flex: '1 0 0',
    minW: '0',
    h: '57px',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow:
      '0px 7px 20px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      bgGradient: 'linear(to-b, alpha.300, alpha.400)',
    },
    display: {
      base: 'none',
      xl: 'none',
    },
    _last: { display: 'flex' },

    sx: {
      '&:nth-child(1), &:nth-child(2)': {
        display: { sm: 'flex' },
      },

      '&:nth-child(3), &:nth-child(4)': {
        display: { md: 'flex' },
      },
    },

    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      mx: 'auto',
      h: '3px',
      w: '25px',
      bg: '#FCD360',
      boxShadow: '0px 0px 8px 0px rgba(252, 211, 96, 0.5)',
      borderBottomRadius: '2px',
    },
  },

  linkBox: {
    display: 'flex',
    flex: '1 0 0',
    minW: '0',
    h: '57px',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow:
      '0px 7px 20px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      bgGradient: 'linear(to-b, alpha.300, alpha.400)',
    },
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      mx: 'auto',
      h: '3px',
      w: '25px',
      bg: '#FCD360',
      boxShadow: '0px 0px 8px 0px rgba(252, 211, 96, 0.5)',
      borderBottomRadius: '2px',
    },
  },

  linkOverlay: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1',
    pb: '1',
    pt: '2',
    px: '0.5',
    justifyContent: 'center',
    alignItems: 'center',
  },

  linkBoxText: {
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'bold',
    fontSize: '8px',
    textTransform: 'uppercase',
    maxW: '80px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  racingHeader: { display: 'none' },
  sportsHeader: { display: 'none' },

  sportsHeaderTile: {
    color: 'white',
    fontFamily: 'accent',
    fontSize: '8px',
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'center',
    fontWeight: 'bold',
    maxW: '80px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  linkOverlayOverride: {
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'bold',
    fontSize: '8px',
    textTransform: 'uppercase',
    w: '58px',
  },
  sportIconOverride: {
    color: 'white',
    textAlign: 'center',
    w: '56px',
  },
};
