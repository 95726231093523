import { CSSObject } from '@chakra-ui/react';

export const GreenIDContainer: CSSObject = {
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  maxW: ['100vw', null, '500px'],
  bg: 'beta.600',
  'button#greenid-option-list-toggle': {
    h: '38px',
    borderRadius: 'base',
    bg: 'blackAlpha.500',
    color: 'white',
    border: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    ':hover': {
      bg: 'blackAlpha.600',
      color: 'white',
    },
  },
  '#greenid-source-header #greenid-source-title': {
    '&&': {
      '#greenid-source-title-heading': {
        color: 'white',
        fontWeight: 'medium',
        fontSize: 'sm',
        fontFamily: 'roboto',
      },
    },
    'a.greenid-modal-launch': {
      textDecor: 'none',
      '.glyphicon-question-sign': {
        color: 'alpha.300',
      },
    },
  },
  '#greenid-intro-words': {
    '.lead': {
      color: 'white',
      fontSize: 'xs',
      fontFamily: 'roboto',
      fontWeight: 'normal',
    },
  },
  '#greenid-intro-content': {
    h1: {
      color: 'alpha.300',
      fontSize: '16px',
      fontFamily: 'accent',
      fontStyle: 'normal',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      mb: '4',
      gap: '5px',
      mt: 0,
    },
    p: {
      color: 'white',
      fontWeight: 'normal',
      fontSize: 'sm',
      fontFamily: 'roboto',
    },
  },
  '&&': {
    '#greenid-container': {
      bg: 'unset',
      h2: {
        color: 'white',
      },
      a: {
        color: 'white',
      },
      '.greenid-helpicon': {
        color: '#1CD5FF',
      },
      '#greenid-source-header': {
        display: 'flex',
        flexDirection: 'column',
      },
      '#greenid-source-content': {
        borderTop: '1px solid',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset',
        borderTopColor: 'blackAlpha.500',
        '#greenid-source-body': {
          p: {
            color: 'white',
            strong: {
              color: 'white',
            },
          },
          '.row': {
            '.form-group': {
              select: {
                h: '38px',
                borderRadius: 'base',
                bg: 'blackAlpha.500',
                color: 'white',
                border: 'none',
                boxShadow:
                  '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
                option: {
                  color: 'black',
                },
              },
              '.control-label': {
                color: 'white',
              },
              'label[for="greenid-bdm-stateselect"]': {
                color: 'white',
              },
              label: {
                color: 'black',
                fontWeight: 'medium',
                fontSize: 'sm',
                fontFamily: 'roboto',
                span: {
                  color: 'white',
                  fontWeight: 'medium',
                  fontSize: 'sm',
                  fontFamily: 'roboto',
                },
              },
              'input[type="text"]': {
                borderLeftRadius: 'base',
                bg: 'blackAlpha.500',
                boxShadow:
                  '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
                border: 'none',
                color: 'white',
                fontWeight: 'normal',
                '&&:hover, &&:focus': {
                  bg: 'blackAlpha.400',
                },
                _placeholder: {
                  color: 'white',
                },
                h: '38px',
              },
            },
          },
          '.checkbox': {
            'input[type="checkbox"]': {
              width: '20px',
              height: '20px',
              bg: 'alpha.400',
              borderWidth: '2px',
              borderColor: 'gray.200',
              marginLeft: '-28px',
              borderRadius: 'sm',

              '&[data-checked]': {
                width: '20px',
                height: '20px',
                bg: 'alpha.400',
                borderColor: 'alpha.400',
              },
              '&[data-checked]:hover': {
                width: '20px',
                height: '20px',
                bg: 'alpha.400',
                borderColor: 'alpha.400',
              },
            },
            label: {
              fontSize: 'xs',
              color: 'white',
              paddingLeft: '28px',
            },
          },
        },
      },
      '.input-group-addon': {
        bg: 'blackAlpha.500',
        border: 'none',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
        '.glyphicon-calendar': {
          color: 'alpha.300',
        },
      },
    },
  },
  '#greenid-submit-button-container': {
    button: {
      position: 'relative',
      borderRadius: 'md',
      bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500), var(--bc-colors-alpha-300))',
      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
      color: 'blackAlpha.800',
      fontFamily: 'accent',
      fontSize: 'xs',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
      zIndex: 1,
      transition: 'all 0.5s ease-in-out',
      _before: {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '2px',
        left: '2px',
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        zIndex: '-1',
        borderRadius: 'base',
        bg: 'linear-gradient(180deg, var(--bc-colors-alpha-300), var(--bc-colors-alpha-500))',
        transition: 'all 0.5s ease-in-out',
      },
      _hover: {
        bg: 'linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-200))',
        color: 'blackAlpha.800',
        _before: {
          bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500), var(--bc-colors-alpha-300))',
        },
      },
      ':active, &[data-active=true]': {
        bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'alpha.300',
        textShadow: 'none',
        _before: {
          display: 'none',
        },
      },
      _disabled: {
        opacity: 0.4,
        pointerEvents: 'none',
      },

      '&&&': {
        '& > svg, & > span': { zIndex: 9 },
      },
      mb: '0',
      w: 'full',
    },
  },
};
