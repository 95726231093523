import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  px: '0',
  pt: '3',
  bg: 'transparent',
  borderRadius: 'base',
  flex: '1',
};

export const SelectFieldContainer: CSSObject = {
  w: 'unset',
  gap: '1',
};

export const CheckboxBoxed: CSSObject = {
  color: 'beta.500',
  right: 0,
  justifyContent: 'flex-end',
  alignItems: 'center',
  mr: '3',
  my: 'auto',
  w: 'auto',
};

export const TextBoxed: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontFamily: 'accent',
  fontWeight: 'semibold',
};
