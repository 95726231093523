import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'white',
  },
  placeNumber: {
    fontWeight: 'medium',
    fontSize: 'sm',
    textTransform: 'capitalize',
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '2',
    pt: '2',
    _last: {
      borderBottom: 'none',
    },
  },
  eventMarketName: {
    fontSize: 'xs',
    color: 'beta.300',
    textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    fontFamily: 'accent',
  },
  eventSubtitle: {
    fontSize: 'xs',
    color: 'gray.50',
    fontWeight: 'medium',
  },
};
