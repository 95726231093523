import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInput: {
    fontFamily: 'accent',
    sx: {
      '&&': {
        px: '2',
      },
    },
  },
};
