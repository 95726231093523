import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  px: 2,
  py: 1,
  mx: 3,
  mb: 1.5,
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'lg',
  _last: {
    mb: 3,
  },
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};
export const IconSGM: CSSObject = {
  w: '35px',
  display: 'block',
  mr: '2',
  color: 'beta.200',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.2)',
  textTransform: 'capitalize',
  ...(isVS && { color: 'beta.300', mx: '1.5', textTransform: 'lowercase' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'semibold',
  fontFamily: 'accent',
  flex: 1,
};

export const IconSport: CSSObject = {
  p: '2',
  boxSize: '32px',
  color: 'blackAlpha.800',
  bg: 'unset',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
};

export const IconChevron: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.timestamp': {
    color: 'white',
    fontSize: '9px',
    bg: 'gray.500',
    fontFamily: 'accent',
    fontWeight: 'bold',
    px: '1',
    pt: '0.5',
    borderRadius: '3px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    lineHeight: 'normal',
    h: '16px',
  },
  '.chakra-badge': {
    fontSize: '9px',
    pt: '0.5',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.400',
    boxSize: '5',
  },
};
