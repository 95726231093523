import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  alignContent: 'center',
  pt: '16',
  textAlign: 'center',
  width: '100%',
  pr: '0',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontWeight: 'bold',
  fontSize: '22px',
  fontFamily: 'heading',
  textTransform: 'uppercase',
  color: 'beta.300',
  mt: '0',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'accent',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};
