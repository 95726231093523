import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '1',
    '.chakra-input__group': {
      borderRadius: 'md',
      p: '1',
      bg: 'alpha.800',
      border: 'none',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.13), 0px 3px 6px 0px rgba(0, 0, 0, 0.2) inset',
      ':has(input:focus)': {
        bg: 'blackAlpha.500',
        border: '2px solid',
        borderColor: 'beta.300',
        boxShadow: '0px 0px 6px 0px rgba(252, 211, 96, 0.6)',
      },
      ':has(input:hover)': {
        bg: 'blackAlpha.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.13), 0px 3px 6px 0px rgba(0, 0, 0, 0.2) inset',
      },
    },
  },
};

export const ThemedInputAddon = (): CSSObject => ({
  h: '35px',
  w: '35px',
  border: 'none',
  fontWeight: 'bold',
  px: '0',
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'accent',
  borderRadius: 'base',
  alignItems: 'center',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'whiteAlpha.200',
};

export const ReviewStakeText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'accent',
};

export const ReviewStake: CSSObject = {
  fontSize: 'sm',
  color: 'beta.300',
  fontWeight: 'black',
  fontFamily: 'accent',
};

export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'accent',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'beta.300',
  fontWeight: 'black',
  fontFamily: 'accent',
};

export const InputBetSlip: CSSObject = {
  pl: '10',
  fontSize: 'sm',
  fontWeight: 'bold',
  borderLeft: 'none',
  borderLeftRadius: 'none',
  pr: '2',
  h: '33px',
  fontFamily: 'accent',
  _placeholder: {
    fontWeight: 'bold',
  },
  _focus: {
    boxShadow: 'none',
    m: '0',
  },
  '&::placeholder': {
    fontFamily: 'accent',
    fontWeight: 'bold',
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'beta.500',
};
