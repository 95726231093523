import { EThemesOG } from '@/constants/themeConfig';
import * as sterlingparker from './HomeLinks.styles.sterlingparker';
import * as goldenrush from './HomeLinks.styles.goldenrush';
import * as juicybet from './HomeLinks.styles.juicybet';
import * as forsport from './HomeLinks.styles.forsport';
import * as oldgill from './HomeLinks.styles.oldgill';
import * as buffalobet from './HomeLinks.styles.buffalobet';
import * as templebet from './HomeLinks.styles.templebet';
import * as bet777 from './HomeLinks.styles.bet777';
import * as slambet from './HomeLinks.styles.slambet';
import * as gigabet from './HomeLinks.styles.gigabet';
import * as titanbet from './HomeLinks.styles.titanbet';
import * as goldenbet888 from './HomeLinks.styles.goldenbet888';
import * as pyramidbet from './HomeLinks.styles.pyramidbet';
import * as magnumbet from './HomeLinks.styles.magnumbet';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  oldgill,
  goldenrush,
  juicybet,
  sterlingparker,
  forsport,
  buffalobet,
  templebet,
  bet777,
  slambet,
  gigabet,
  titanbet,
  goldenbet888,
  pyramidbet,
  magnumbet,
};
