import { CSSObject } from '@chakra-ui/react';

export const LinkContainer = () => ({
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.3)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});
export const BoxNextToJumpContainer: CSSObject = {
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  mx: '0',
  borderRadius: 'md',
  '> div:last-child': {
    'a[class*="nextToJump-LinkContainer"]': {
      borderBottom: 'none',
      boxShadow: 'none',
    },
  },
};
export const Icon: CSSObject = {
  boxSize: '7',
  color: 'blackAlpha.800',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  border: 'none',
  borderRadius: 'md',
  p: '2',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 2px 1px rgba(255, 255, 255, 0.25) inset',
  mr: '2',
};
export const TextRace: CSSObject = {
  color: 'white',
  textTransform: 'capitalize',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'bold',
};
export const TextNumber: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'gray.300',
  color: 'gray.600',
  py: '0',
  pt: 'px',
  borderRadius: '3px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
};
export const BoxHeading: CSSObject = {
  display: 'none',
};
export const FlexWrapper: CSSObject = {
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    mr: '0.5',
  },
};
