import * as puntgenie from './BetCardInput.styles.puntgenie';
import * as templebet from './BetCardInput.styles.templebet';
import * as slambet from './BetCardInput.styles.slambet';
import * as goldenbet888 from './BetCardInput.styles.goldenbet888';
import * as oldgill from './BetCardInput.styles.oldgill';
import * as bet777 from './BetCardInput.styles.bet777';
import * as titanbet from './BetCardInput.styles.titanbet';
import * as betblast from './BetCardInput.styles.betblast';
import * as magnumbet from './BetCardInput.styles.magnumbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  puntgenie,
  templebet,
  slambet,
  goldenbet888,
  oldgill,
  bet777,
  titanbet,
  betblast,
  magnumbet,
};
