import * as betprofessor from './Promos.styles.betprofessor';
import * as puntcity from './Promos.styles.puntcity';
import * as goldenrush from './Promos.styles.goldenrush';
import * as juicybet from './Promos.styles.juicybet';
import * as junglebet from './Promos.styles.junglebet';
import * as betroyale from './Promos.styles.betroyale';
import * as forsport from './Promos.styles.forsport';
import * as fiestabet from './Promos.styles.fiestabet';
import * as oldgill from './Promos.styles.oldgill';
import * as buffalobet from './Promos.styles.buffalobet';
import * as gigabet from './Promos.styles.gigabet';
import * as slambet from './Promos.styles.slambet';
import * as goldenbet888 from './Promos.styles.goldenbet888';
import * as sugarcastle from './Promos.styles.sugarcastle';
import * as betstride from './Promos.styles.betstride';
import * as bet777 from './Promos.styles.bet777';
import * as chromabet from './Promos.styles.chromabet';
import * as titanbet from './Promos.styles.titanbet';
import * as bet575 from './Promos.styles.bet575';
import * as betblast from './Promos.styles.betblast';
import * as magnumbet from './Promos.styles.magnumbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  puntcity,
  goldenrush,
  juicybet,
  junglebet,
  betroyale,
  forsport,
  fiestabet,
  oldgill,
  buffalobet,
  gigabet,
  slambet,
  goldenbet888,
  sugarcastle,
  betstride,
  bet777,
  chromabet,
  titanbet,
  bet575,
  betblast,
  magnumbet,
};
