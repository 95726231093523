import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textTransform: 'uppercase',
  fontSize: ['lg', '25px'],
  fontWeight: 'bold',
  fontFamily: 'accent',
  textAlign: 'center',
  color: 'alpha.200',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};
export const TextSecondary: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  w: ['288px', null, '420px'],
  textAlign: 'center',
  fontFamily: 'accent',
  fontWeight: 'normal',
  fontSize: 'md',
  p: {
    w: '100%',
    fontWeight: 'normal',
  },
};
export const ImageNoRaces: CSSObject = {
  w: ['300px', null, '550px'],
  borderRadius: 'lg',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.30))',
  mb: '0',
};

export const NoRacesWrapper: CSSObject = {
  minH: '50vh',
};
