import { CSSObject } from '@chakra-ui/react';

export const FlexPageWrapper: CSSObject = {
  px: '0',
  pb: '1',
  mt: '3',
  color: 'white',
  '&&': {
    '> div': {
      fontFamily: 'accent',
    },
  },
};
